.footer-container {
  background-color: rgba(239, 241, 244, 1);
}

.footer-content {
  display: flex;
  justify-content: space-between;
  padding: 0 2%;
  margin: 0px 0px;
}

.footer-column {
  flex-basis: 40%;
  text-align: left;
  padding: 70px 0px;
}

.footer-column a {
  color: #3e3e3e;
  text-decoration: none;
}

.footer-column p {
  color: #3e3e3e;
  font-size: 18px;
}
.footer-title {
  font-weight: bold;
  font-size: 36px;
  color: #020201;
  padding: 23px 0px;
  margin: 0px 30px;
  background-color: #ffd700;
  text-align: center;
  align-items: center;
}

.footer-description {
  font-size: 16px;
  color: #3e3e3e;
  line-height: 1;
  margin: 15px 65px;
  text-align: center;
}

.footer-heading {
  color: #3e3e3e;
}


.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  line-height: 1.8;
  font-size: 16px;
}

.footer-links a {
  color: #6a6a6a;
  font-weight: bold;
  text-decoration: none;
}

.footer-social {
  margin-top: 2%;
}

.social-link {
  color: #3e3e3e;
  font-size: 14px;
  margin-right: 10px;
}

.social-link:hover {
  background-color: #666;
}

.footer-bottom {
  background-color: rgba(41, 45, 49, 1);
  padding: 1% 0;
  text-align: right;
}

.footer-bottom p {
  color: #898989;
  font-size: 12px;
}
@media (max-width: 768px) {
  .footer-content {
    display: block;
  }
  .footer-column {
    padding: 4px 0px;
    text-align: center;
  }
  .footer-heading {
    color: #ffd700;
    font-size: 24px;
    padding: 0;
    margin: 0;
  }
  .footer-title {
    margin-top: 26px;
  }
}
