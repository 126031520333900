.whatsapp-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
    width: 60px;
    height: 60px;
    background-color: #25d366;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .whatsapp-icon img {
    width: 40px;
    height: 40px;
  }
  
  .whatsapp-icon:hover {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  }
  