.contenedor-tres-columnas {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: rgba(255, 248, 41, 1);
  }
  
  .columna {
    width: 30%;
    padding: 0 10px;
  }
  .textDescrip{
    font-size: 15px;
    margin: 8px 35px;
  }
  
  .icono-seccion {
    margin: 145px 0;
    text-align: center;
  }
  
  .icono-seccion i {
    font-size: 24px;
    color: #000;
    margin-bottom: 10px;
  }
  
  .icono-seccion h1 {
    font-size: 26px;
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
  }
  
  .icono-seccion p {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.74);
  }
  
  .imagen {
    text-align: center;
  }
  
  .imagen img {
    width: 100%;
    height: 420px;
    border-radius: 0px;
  }
  .w3030{
    width: 30px;
    height: 30px;
  }
  .margen0{
    margin: 0;
  }
  .margen40{
    margin: 40px 0px;
  }
  .imagen h2 {
    font-size: 24px;
    font-weight: bold;
    color: #000;
    margin-bottom: 20px;
  }
  .blanco{
    color: #fff;
  }
  /* Para pantallas más pequeñas (tablets y móviles) */
@media (max-width: 768px) {
    .contenedor-tres-columnas {
      flex-direction: column;
      align-items: center;
    }
    .columna {
      width: 100%;
      margin-bottom: 20px;
    }
    .imagen img {
      width: 90%;
      height: auto;
    }
    .icono-seccion h4 {
      font-size: 16px;
    }
    .icono-seccion p {
      font-size: 12px;
    }
    .icono-seccion {
      margin: 25px 0;
      text-align: center;
    }
    .imagen {
      display: none;
    }
    .tituloSS{
      display: none;
    }
  }
  
  /* Para pantallas muy pequeñas (móviles) */
  @media (max-width: 480px) {
    .imagen h2 {
      font-size: 20px;
    }
  
    .icono-seccion h4 {
      font-size: 14px;
    }
  
    .icono-seccion p {
      font-size: 10px;
    }
  
    .imagen img {
      width: 100%;
    }
  }
  