.contenedor-seccion-lado-der {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 0;
    padding: 60px;
    background-color: rgba(242, 243, 247, 1);
  }
  
  .lado-izquierdo-lado-der {
    flex: 1;
    margin-right: 20px;
  }
  
  .imagen-seccion-lado-der {
    width: 100%;
    height: 470px;
    border-radius: 1px;
    object-fit: cover;
  }
  
  .lado-derecho-lado-der {
    flex: 1;
    text-align: left;
    margin: 20px 50px;
  }
  
  .titulo-seccion-lado-der {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 25px;
  }
  
  .linea-amarilla-lado-der {
    width: 100%;
    border: 1px solid #ffd700;
    margin-bottom: 25px;
  }
  
  .descripcion-seccion-lado-der {
    font-size: 19px;
    margin-bottom: 20px;
  }
  
  .boton-seccion-lado-der {
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
  }
  @media (max-width: 768px) {
    .contenedor-seccion-lado-der {
      display: block;
      padding: 10px;
      padding-bottom: 37px;
    }
    .lado-derecho-lado-der {
      text-align: center;
      margin: 7px 20px;
      padding-bottom: 37px;
    }
    .titulo-seccion-lado-der  {
      font-size: 30px;
    }
    .linea-amarilla-lado-der  {
      margin-bottom: 12px;
    }
    .descripcion-seccion-lado-der  {
      font-size: 15px;
    }
    .lado-izquierdo-lado-der {
      margin-right: 0px;
    }
    .imagen-seccion-lado-der {
      height: 360px;
    }
  }