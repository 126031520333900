.contenedor-seccion-TxT-Centrado-LadoDER {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 0;
    padding: 90px;
    background-color: rgba(242, 243, 247, 1);
  }
  
  .lado-izquierdo-TxT-Centrado-LadoDER {
    flex: 1;
    margin: 0px 60px;
    background-position: center center;
  }
  
  .imagen-seccion-TxT-Centrado-LadoDER {
    width: 95%;
    height: 540px;
    border-radius: 0px;
    object-fit: cover;
  }
  
  .lado-derecho-TxT-Centrado-LadoDER {
    flex: 1;
    text-align: center;
    margin: 20px 50px;
  }
  
  .titulo-seccion-TxT-Centrado-LadoDER {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 25px;
  }
  
  .descripcion-seccion-TxT-Centrado-LadoDER {
    font-size: 19px;
    margin-bottom: 52px;
  }
  
  .boton-seccion-TxT-Centrado-LadoDER {
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    .contenedor-seccion-TxT-Centrado-LadoDER {
      display: block
    }
    .contenedor-seccion-TxT-Centrado-LadoDER {
      padding: 30px;
    }
    .imagen-seccion-TxT-Centrado-LadoDER {
      width: 100%;
      height: 300px;
    }
    .lado-izquierdo-TxT-Centrado-LadoDER {
      margin: 0px 5%;
    }
    .lado-derecho-TxT-Centrado-LadoDER {
      margin: 0px 5%;
    }
    .BannerDerImagen-image img {
      height: 350px;
  }
  }
  @media (max-width: 320px) {
    
  }