.btnColor {
    background-color: #ffd700;; /* Botón amarillo */
    color: black;
    cursor: pointer;
    text-decoration: none;
  }
  .btnColor a {
    text-decoration: none;
    color: #000000;
  }
  .btnColor:hover {
    background-color: #21214a; /* Efecto hover en el botón */
    color: #fff
  }

