.contenedor-industek-TxTCentrado {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    /*background-color: #edfff1; /* Fondo verde cálido suave */
    padding: 80px 40px;
    min-height: 190px; /* Asegura que ocupe toda la altura de la pantalla */
  }
  
  .titulo-grande-TxTCentrado {
    font-size: 2rem;
    font-weight: bold;
    margin: 10px 0;
  }
  
  .titulo-pequeno-TxTCentrado {
    font-size: 1rem;
    margin: 10px 0;
  }
  
  .descripcion-TxTCentrado {
    font-size: 0.7rem;
    max-width: 800px;
    margin: 20px auto;
    line-height: 1.5;
  }
  
  /* Responsive para pantallas pequeñas */
  @media (max-width: 768px) {
    .titulo-grande-TxTCentrado {
      font-size: 1.5rem;
    }
  
    .titulo-pequeno-TxTCentrado {
      font-size: 1rem;
    }
  
    .descripcion-TxTCentrado {
      font-size: 0.8rem;
      padding: 0 15px;
    }
  }
  