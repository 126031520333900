.contenedor-seccion-Doble-TxT {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 0;
    padding: 60px;
    background-color: rgba(242, 243, 247, 1);
  }
  
  .lado-izquierdo-Doble-TxT {
    flex: 1;
    margin-right: 20px;
  }
  
  .imagen-seccion-Doble-TxT {
    width: 100%;
    height: 470px;
    border-radius: 0px;
    object-fit: cover;
  }
  
  .lado-derecho-Doble-TxT {
    flex: 1;
    text-align: center;
    margin: 20px 50px;
  }
  
  .titulo-seccion-Doble-TxT {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 25px;
  }

  .titulo2-seccion-Doble-TxT{
    font-size: 80px;
    font-weight: bold;
    margin-bottom: 25px;
    color: #ffd700;
  }
  
  .descripcion-seccion-Doble-TxT {
    font-size: 19px;
    margin-bottom: 52px;
  }
  .boton-seccion-Doble-TxT {
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
  }
  @media (max-width: 768px) {

  .contenedor-seccion-Doble-TxT {
    display: block;
    padding: 6%;
  } 
  .titulo2-seccion-Doble-TxT {
    display: none;
  }
  .titulo-seccion-Doble-TxT {
    font-size: 1.2rem;
  }
  }

  