/* Contenedor grid */
.custom-grid-container-bannerImageIz {
    display: grid;
    grid-template-columns: 1fr 2fr; /* Un tercio para la imagen, dos tercios para el contenido */
    gap: 20px;
    text-align: left;
    align-items: center;
    padding: 50px;
    background-color: rgba(239, 241, 244, 1);;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Contenedor de la imagen */
  .image-container-bannerImageIz {
    display: flex;
    justify-content: left;
    align-items: left;
  }
  
  /* Imagen */
  .image-bannerImageIz {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    opacity: 0.9;
  }
  
  /* Contenedor de contenido textual */
  .content-container-bannerImageIz {
    flex-direction: column;
    margin: 16px;
  }
  
  /* Título pequeño */
  .small-title-bannerImageIz {
    font-size: 0.7rem;
    color: #777;
    margin: 12px 0px;
  }
  
  /* Título principal */
  .title-bannerImageIz {
    font-size: 2rem;
    color: #333;
    margin: 15px 0px;
    text-align: left;
  }
  
  /* Descripción */
  .description-bannerImageIz {
    font-size: 1rem;
    color: #555;
    margin-bottom: 15px;
    line-height: 1;
  }
  
  /* Características */
  .features-bannerImageIz {
    display: contents;
    margin-bottom: 20px;
  }
  
  .feature-bannerImageIz {
    font-size: 1rem;
    margin: 35px 3px;
    color: #444;
  }
  
  /* Botón de cotización */
  .quote-button-bannerImageIz {
    padding: 20px 40px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    margin-bottom: 30px;
    margin-top: 27px;
    text-align: center;
  }
  @media (max-width: 768px) {
    .custom-grid-container-bannerImageIz {
      display: block;
      padding: 20px;
    }
    .image-container-bannerImageIz {
      height: 460px;
      width: 100%;
    }
    .description-bannerImageIz {
      font-size: 0.8rem;
    }
    .feature-bannerImageIz {
      font-size: 0.8rem;
    }
  }

  