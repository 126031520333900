/* src/components/Menu.css */
.menu {
  z-index: 10;
  background-color: #333;
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;  /* Utilizamos Flexbox para manejar la distribución de los elementos */
  justify-content: space-between; /* Espacio entre el logotipo y el menú */
  align-items: center; /* Alineamos verticalmente los elementos al centro */
}

.menu-logo {
 width: 170px; /* Aquí puedes ajustar el tamaño del logotipo si es necesario */
 height: auto;
 margin: 0px 20px;
}

.menu-list {
  display: flex;
  justify-content: flex-end; /* Los items se alinean hacia la derecha */
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-item {
  margin: 0 15px;
}

.menu-item a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  transition: background-color 0.3s, color 0.3s;
  
}

.menu-item a:hover {
  background-color: #555555;
  color: #f5e61e;
  border-radius: 5px;
}
/* Menu hamburguesa para pantallas pequeñas */
.menu-button {
  display: none;
  font-size: 24px;
  color: #fff;
  background: transparent;
  border: none;
  cursor: pointer;
}
/* Responsive styles */
@media (max-width: 768px) {
  .menu {
    padding: 0px;
  }
  .menu-item {
    margin: 20px 0;
  }
  .menu-item a {
    font-size: 20px;
    padding: 20px;
    width: 100%;
  }
  .menu-button {
    display: block;
  }
  .menu-list {
    display: none;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #333;
    flex-direction: column;
    align-items: center;
  }
  .menu-list.active {
    display: flex;
    top: 60px;
    padding-bottom: 16px;
    z-index: 3;
  }
}
