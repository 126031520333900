.contenedor-seccion-TxT-Centrado {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 0;
    padding: 60px;
    background-color: rgba(242, 243, 247, 1);
  }
  
  .lado-izquierdo-TxT-Centrado {
    flex: 1;
    margin-right: 20px;
  }
  
  .imagen-seccion-TxT-Centrado {
    width: 100%;
    height: 470px;
    border-radius: 0px;
    object-fit: cover;
  }
  
  .lado-derecho-TxT-Centrado {
    flex: 1;
    text-align: center;
    margin: 20px 50px;
  }
  
  .titulo-seccion-TxT-Centrado {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 25px;
  }
  
  .descripcion-seccion-TxT-Centrado {
    font-size: 19px;
    margin-bottom: 52px;
  }
  
  .boton-seccion-TxT-Centrado {
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    text-decoration: none;
  }
  
  @media (max-width: 768px) {
  .contenedor-seccion-TxT-Centrado {
    display: block;
  }
  .lado-derecho-TxT-Centrado {
    margin: 0px;
    margin-bottom: 50px ;
  }

  }