.contenedor-grid-section-Grid2X2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    /*background-color: #f0f0f0; /* Fondo claro */
    text-align: center;
  }
  
  .titulo-principal-Grid2X2 {
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #333;
  }
  
  /* Diseño de la cuadrícula */
  .grid-container-Grid2X2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columnas */
    gap: 20px; /* Espacio entre elementos */
    max-width: 1000px;
    width: 100%;
  }
  
  .grid-item-Grid2X2 {
    background-color: rgba(242, 243, 247, 1);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .grid-item-Grid2X2:hover {
    transform: translateY(-5px);
  }
  
  .titulo-grid-Grid2X2 {
    font-size: 1.1rem;
    margin-bottom: 10px;
    color: #f5e61e;;
  }
  
  .descripcion-grid-Grid2X2 {
    font-size: 0.7rem;
    color: #555;
  }
  
  /* Responsivo para pantallas pequeñas */
  @media (max-width: 768px) {
    .grid-container-Grid2X2 {
      grid-template-columns: 1fr; /* Cambia a una sola columna en pantallas pequeñas */
    }
  
    .titulo-principal-Grid2X2 {
      font-size: 2rem;
    }
  
    .titulo-grid-Grid2X2 {
      font-size: 1.3rem;
    }
  
    .descripcion-grid-Grid2X2 {
      font-size: 0.9rem;
    }
  }
  