/* Contenedor principal */
.grid-container-Icon {
    display: grid;
    grid-template-columns: repeat(3, 1fr);  /* 3 columnas iguales */
    gap: 20px;  /* Espaciado entre las columnas */
    padding: 70px 40px;
    background-color: #f9f9f9; /* Fondo general */
  }
  
  .main-title-Icon {
    grid-column: span 3;  /* Ocupa todas las columnas */
    text-align: center;
    margin: 30px 30px;
    font-size: 2.2rem;
    color: #333;
  }

  .grid-item-Icon {
    background-color: white;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  /* Sombra suave */
    text-align: center;
    transition: transform 0.3s ease;
    height: 380px;
  }
  
  .item-image-Icon {
    max-width: 100%;
    border-radius: 10px 10px 0px 0px;
    margin-bottom: 15px;
  }
  
  /* Título de cada sección */
  .item-title-Icon {
    font-size: 1.2rem;
    margin: 30px 20px;
    color: #68665b;
    line-height: 1;
  }
  
  /* Descripción de cada sección */
  .item-description-Icon {
    font-size: 1rem;
    color: #777;
    line-height: 1.5;
    margin: 12% 14%;
  }
  
  /* Efecto de hover en cada grid-item */
  .grid-item-Icon:hover {
    transform: translateY(-5px);  /* Efecto de elevación */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  .icono-titulo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 22px 0px;
  }
  
  .icono {
    width: 50px;
    height: 50px;
    margin-right: 10px; /* Espacio entre ícono y título */
  }
  
  .titulo-grid2 {
    font-size: 25px;
    color: #f5e61e;
    width: 170px;
    text-shadow: 1px  0px 0px black,
               0px  1px 0px black,
              -1px  0px 0px black,
               0px -1px 0px black;
  }
  .boton-grid {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    margin-bottom: 45px;
  }
  
  
  /* Pantallas pequeñas (móviles) */
@media (max-width: 768px) {
  .grid-container-Icon {
    grid-template-columns: 1fr;
    display: contents;
  }
  .main-title-Icon {
    font-size: 1.3rem; 
  }
  .grid-item-Icon {
    padding: 10px;
  }
  .item-title-Icon {
    font-size: 1.3rem; 
  }
  .item-description-Icon {
    font-size: 0.9rem; 
    margin: 7% 10%;
  }
  .titulo-grid2 {
    width: 360px;
  }
  .grid-item-Icon {
    padding: 40px;
    margin: 35px;
    height: 300px;

}
}
@media (max-width: 470px) {
  .grid-item-Icon {
    padding: 10px;
  }
}
  
