.contenedor-industek-TxTCentradoColor {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: rgba(242, 243, 247, 1);
    padding: 20px;
    min-height: 190px; /* Asegura que ocupe toda la altura de la pantalla */
  }
  .descripcion-TxTCentradoColor {
    font-size: 0.7rem;
    max-width: 700px;
    margin: 20px auto;
    line-height: 1.5;
    text-align: justify;
  }
  /* Responsive para pantallas pequeñas */
  @media (max-width: 768px) {
    .descripcion-TxTCentrado {
      font-size: 0.8rem;
      padding: 0 10px;
    }
  }