.containerTextCentral {
  background-color: black;
  height: 570px;
  display: flex;
  flex-direction: column; /* Para alinear los elementos (imagen y texto) en una columna */
  justify-content: center; /* Centrar verticalmente */
  align-items: center; /* Centrar horizontalmente */
  text-align: center;
}

.Textocentrado-text-image {
  background-color: black;
  margin-bottom: 20px; 
  margin-top: 0px;
  max-width: 100%; /* Asegura que la imagen no se salga de los bordes */
  height: auto; /* Mantiene la proporción */
}

.Textocentrado-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: black;
}

.Textocentrado-text {
  font-size: 33px;
  color: white;
  max-width: 800px; /* Limita el ancho del texto */
  line-height: 1.2;
  margin: 0; /* Elimina cualquier margen adicional */
}

.TextoColor {
  color: rgb(252, 244, 29);
  font-weight: bold; /* Hace que las palabras resaltadas sean más notables */
}
