.contenedor-seccion {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 0;
    padding: 60px;
  }
  
  .lado-izquierdo {
    flex: 1;
    margin-right: 20px;
  }
  
  .imagen-seccion-LadoIZQ {
    width: 100%;
    height: 470px;
    border-radius: 1px;
    object-fit: cover;
  }
  
  .lado-derecho {
    flex: 1;
    text-align: left;
    margin: 20px 50px;
  }
  
  .titulo-seccion {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 25px;
  }
  
  .linea-amarilla {
    width: 100%;
    border: 1px solid #ffd700;
    margin-bottom: 25px;
  }
  
  .descripcion-seccion {
    font-size: 19px;
    margin-bottom: 20px;
  }
  
  .boton-seccion{
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
  }
  
  
  @media (max-width: 768px) {
    .contenedor-seccion {
      display: block;
      padding: 10px;
      margin-bottom: 29px;
    }
    .lado-izquierdo {
      margin-right: 0px;
    }
    .lado-derecho {
      text-align: center;
      margin: 7px 20px;
    }
    .imagen-seccion-LadoIZQ {
      height: 300px;
    }
    .titulo-seccion {
      font-size: 30px;
    }
    .linea-amarilla {
      margin-bottom: 12px;
    }
    .descripcion-seccion {
      font-size: 15px;
    }
  }