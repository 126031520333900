/* src/components/Header.css */
.header {
    position: relative;
    width: 95%;
    height: 94vh;
    min-height: 600px;
    background-image: "url(/logo512.png)";
    background-size: cover;
    background-position: center;
    color: #fff;
    text-align: center;
  }
  
  .banner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    padding: 0px 50px;
    width: 65%;
    background-image: './logo192.png';
  }
  
  .banner h1 {
    font-size: 3rem;
    margin: 0px;
    text-align: left;
    line-height: 1;
    color: #fff;
  }
  
  .banner p {
    font-size: 0.9rem;
    margin: 30px 25px 30px 0px;
    text-align: left;
    line-height: 1;
  }
  
  .cta-button {
    border-radius: 5px;
    font-size: 1.2rem;
    transition: background-color 0.3s;
    padding: 20px 50px;
  }
  
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .banner{
      padding: 0px 0px 0px 15px;
      width: 100%;
    }
    .banner h1 {
      font-size: 2.1rem;
    }
  
    .banner p {
      font-size: 1.1rem;
    }
  
    .cta-button {
      font-size: 0.9rem;
      padding: 14px 38px;
      
    }
  
   }