.fondo-imagen {
    background-size: cover; /* Hace que la imagen cubra todo el contenedor sin distorsión */
    background-position: center;
    width: 100%; /* Se expande de lado a lado */
    height: 140px; /* Reducimos la altura de la imagen */
    position: relative; /* Posición relativa para superponer el contenido */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .colorcalido{
    background-color: #f5e61e;
  }
  .opacidad{
    background-color: #00000072;
  }
  .contenedor-titulo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background-color: #f5e61e; /* Fondo oscuro semitransparente cubriendo toda la imagen */
    display: flex;
    justify-content: center;
    align-items: center;

  }
  
  .titulo {
    color: rgb(255, 255, 255);
    font-size: 2.5rem;
    text-align: center;
    margin: 0;
    padding: 0 20px; /* Espaciado lateral */
  }
  
  @media (max-width: 768px) {
    .fondo-imagen {
      height: 100px; /* Ajusta la altura para pantallas más pequeñas */
    }
    .titulo {
      font-size: 1.8rem; /* Ajusta el tamaño del texto en pantallas pequeñas */
    }
  }
  