/* Contenedor principal */
.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);  /* 3 columnas iguales */
    gap: 20px;  /* Espaciado entre las columnas */
    padding: 70px 40px;
    background-color: #f9f9f9; /* Fondo general */
  }
  
  /* Título principal */
  .main-title {
    grid-column: span 3;  /* Ocupa todas las columnas */
    text-align: center;
    margin: 30px 0px;
    font-size: 2.2rem;
    color: #333;
  }
  
  /* Cada ítem en el grid */
  .grid-item {
    background-color: white;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  /* Sombra suave */
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  /* Estilo para la imagen */
  .item-image {
    max-width: 100%;
    border-radius: 10px 10px 0px 0px;
    margin-bottom: 15px;
  }
  
  /* Título de cada sección */
  .item-title {
    font-size: 1.2rem;
    margin: 30px 20px;
    color: #ffd700;
    line-height: 1;
  }
  
  /* Descripción de cada sección */
  .item-description {
    font-size: 0.7rem;
    color: #777;
    line-height: 1.5;
    margin: 12% 14%;
  }
  
  /* Efecto de hover en cada grid-item */
  .grid-item:hover {
    transform: translateY(-5px);  /* Efecto de elevación */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  /* Pantallas pequeñas (móviles) */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr; /* 1 columna en pantallas pequeñas */
  }

  .main-title {
    font-size: 1.8rem; /* Aún más pequeño en móviles */
  }

  .grid-item {
    padding: 10px; /* Reducir más el padding */
  }

  .item-title {
    font-size: 1.3rem; /* Reducir el tamaño de los títulos */
  }

  .item-description {
    font-size: 0.9rem; /* Reducir el tamaño del texto */
  }
}
/* Media queries para diseño responsive */

@media (max-width: 768px) {
    .grid-container {
     display: contents;
     
    }
  }