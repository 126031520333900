.contenedor-seccion-centrada {
    width: 100%;
    height: 500px; /* Ajusta según lo necesites */
    background-size: cover; /* Asegura que la imagen de fondo cubra el contenedor sin estirarse */
    background-position: center; /* Centra la imagen */
    background-repeat: no-repeat; /* Evita que la imagen se repita */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Necesario para el overlay */
    overflow: hidden; /* Previene que la imagen se salga del contenedor */
  }
  
  .contenido-seccion-centrada {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Fondo semitransparente */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box; /* Asegura que el padding no expanda el contenedor */
  }
  
  .titulo-seccion-centrada {
    font-size: 2rem; /* Ajusta el tamaño de la fuente */
    color: white;
    margin-bottom: 15px;
    text-align: center; /* Mantiene el título centrado */
    max-width: 90%; /* Limita el ancho del texto */
  }
  
  .descripcion-seccion-centrada {
    font-size: 21px;
    color: white;
    margin-bottom: 20px;
    text-align: center; /* Mantiene la descripción centrada */
    max-width: 60%; /* Limita el ancho del texto */
  }
  
  .boton-seccion-centrada {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    text-align: center;
  }
  
  /* Ajustes para imágenes */
  .imagen-seccion {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Asegura que la imagen mantenga sus proporciones sin estirarse */
    border-radius: 10px;
  }
  @media (max-width: 768px) {
    .contenedor-seccion-centrada {
      height: 600px;
    }
    .titulo-seccion-centrada {
      font-size: 1.6rem;
      max-width: 95%;
    }
    .descripcion-seccion-centrada {
      font-size: 19px;
      max-width: 90%;
    }
  }
  