.industek-section-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 40px;
    margin-bottom: 45px;
    /*height: 545px;  Para centrar verticalmente en la pantalla */
  }
  
  .industek-title {
    color: #ffd700; /* Color amarillo */
    font-size: 1.4rem;
    margin-bottom: 20px;
  }
  
  .industek-subtitle {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .industek-description {
    font-size: 1rem;
    margin-bottom: 10px;
    max-width: 800px;
    line-height: 1.2;
  }
  
  .industek-question {
    font-size: 1rem;
    margin: 10px;
  }
  
  .industek-button {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    margin-top: 60px;
    text-decoration: none;
  }
  
  
  @media (max-width: 768px) {
    .industek-subtitle {
      font-size: 25px;
    }
    .industek-description {
      font-size: 15px;
    }
    .industek-question {
      font-size: 15px;
    }
  }
  