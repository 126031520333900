.contenedor-contacto {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 50px;
    background-color: rgba(242, 243, 247, 1);
  }
  
  .columna-contacto {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .texto-contacto h2 {
    font-size: 40px;
    margin-bottom: 20px;
    text-align: center;

  }
  
  .texto-contacto h3 {
    font-size: 18px;
    margin-bottom: 3px;
    text-align: center;
  }
  .textrojo{
    color: #9f1313;
    display: inline;
    font-weight: 900;
  }
  .texto-contacto p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .formulario-contacto input, 
  .formulario-contacto textarea {
    width: 96%;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .formulario-contacto textarea {
    width: 96%;  /* Ajusta el ancho para que ocupe todo el espacio disponible */
    height: 150px;  /* Tamaño fijo de altura */
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    resize: none;
  }
  
  .formulario-contacto button {
    width: 100%;
    padding: 22px 40px;
    background-color: #ffd700;
    border: none;
    color: black;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .formulario-contacto button:hover {
    background-color: #e6c200;
  }
  
  .imagen-contacto img {
    width: 60%;
    height: auto;
    border-radius: 10px;
    align-self: center;
  }
  .BtnEnviar{
    font-size: 20px;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .contenedor-contacto {
      grid-template-columns: 1fr;
      padding-bottom: 100px;

    }
  
    .imagen-contacto {
      margin-top: 20px;
      display: none;
    }
  }
  