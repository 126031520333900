.BannerDerImagen-container {
    display: grid;
    grid-template-columns: 4fr 2fr;
    align-items: left;
    gap: 20px;
    padding: 20px;
    text-align: left;
  }
  .Padd90{
    padding: 90px;
  }
  
  .BannerDerImagen-image img {
    width: 100%;
    height: auto;
  }
  .BannerDerImagen-image2 img{
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .BannerDerImagen-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 25px;
  }
  
  .BannerDerImagen-title {
    font-size: 2rem;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .BannerDerImagen-description {
    font-size: 1rem;
    margin: 35px 0px;
    line-height: 1;
    width: 100%;
    text-align: center;
  }
  .BannerDerImagen-item{
    margin-bottom:  29px;
    font-weight: 600;
  }
  
  .BannerDerImagen-button {
    padding: 10px 20px;
    border: none;
    font-size: 1rem;
    text-align: center;
  }
  
  /* Responsivo */
  @media (max-width: 768px) {
    .BannerDerImagen-content {
      display: block;
    }
    .BannerDerImagen-container {
      text-align: center;
      display: block;
    }
    .BannerDerImagen-image {
      margin-bottom: 20px;
    }
    .BannerDerImagen-description {
      width: 100%;
    }
    .BannerDerImagen-image2{
      height: 400px;
    }
    .Padd90 {
      padding: 10px;
    }
  }
  